import React from "react"
import styled from "styled-components"
import Button from "@material-ui/core/Button"
import { Map } from "@styled-icons/boxicons-regular/Map"
import { Whatsapp } from "@styled-icons/boxicons-logos/Whatsapp"
import { Mail } from "@styled-icons/feather/Mail"

import { Container } from "../../theme/global-style"

const ContatoBg = styled.section`
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 30px 0;
  margin-top: 50px;
`

const Contato = styled(Container)`
  h4 {
    color: ${({ theme }) => theme.colors.white};
  }

  p {
    color: ${({ theme }) => theme.colors.muted};
  }

  div {
    margin-bottom: 50px;
  }

  @media (min-width: ${({ theme }) => theme.screens.md}) {
    display: flex;
    justify-content: space-evenly;

    div {
      width: 30%;
    }
  }
`

const StyledButton = styled(Button)`
  background-color: #25d366;
  color: ${({ theme }) => theme.colors.black};

  &:hover {
    background-color: #25d366;
    color: ${({ theme }) => theme.colors.black};
  }
`

const FooterBg = styled.section`
  background-color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  padding: 30px;
  font-weight: ${({ theme }) => theme.fontWeight.light};
  font-size: ${({ theme }) => theme.fontSize.sm};

  a {
    text-decoration: underline;
  }
`

const Footer = () => (
  <>
    <ContatoBg id="contato">
      <Contato>
        <div>
          <h4>
            <Whatsapp size={23} /> Telefones / WhatsApp
          </h4>
          <p>
            <strong>Érica:</strong>{" "}
            <StyledButton
              variant="contained"
              color="primary"
              size="small"
              href="https://api.whatsapp.com/send?phone=5519988287751"
              startIcon={<Whatsapp size={16} />}
              disableElevation
            >
              19 98828.7751
            </StyledButton>
          </p>
        </div>

        <div>
          <h4>
            <Mail size={20} /> Email
          </h4>
          <p>vendasdecplanejados@gmail.com</p>
        </div>

        <div>
          <h4>
            <Map size={23} /> Localização
          </h4>
          <p>
            Rua Antonio Casemiro, nº 765 - Bairro/Distrito Jd. Voluntário Pedro
            Freitas - CEP: 13.495-248 - Iracemápolis/SP
          </p>
        </div>
      </Contato>
    </ContatoBg>

    <FooterBg>
      © DEC Móveis Planejados {new Date().getFullYear()} - Desenvolvimento
      {` `}
      <a href="https://mzndigital.com">MZN Digital</a>
    </FooterBg>
  </>
)

export default Footer
