import React from "react"
import styled from "styled-components"
import { Time } from "@styled-icons/ionicons-outline/Time"
import { Mail } from "@styled-icons/feather/Mail"

import { Container } from "../theme/global-style"

import SocialGroupIcons from "./socialGroupIcons2"

const Top = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.borderColor.black06};
`

const TopContainer = styled(Container)`
  min-height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.dimgray};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`

const TopInfo = styled.div`
  display: flex;
  align-items: center;
`

const Email = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.normal};

  @media (min-width: ${({ theme }) => theme.screens.lg}) {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    margin-right: 20px;
    padding-right: 20px;
    border-right: 1px solid ${({ theme }) => theme.borderColor.black08};
  }
`

const Hour = styled.div`
  @media (max-width: ${({ theme }) => theme.screens.maxlg}) {
    display: none;
  }
`

const TopSocial = styled.div`
  @media (max-width: ${({ theme }) => theme.screens.maxlg}) {
    display: none;
  }
`

const HeaderTop = () => (
  <Top id="top">
    <TopContainer>
      <TopInfo>
        <Email>
          <Mail size={16} /> vendasdecplanejados@gmail.com
        </Email>
        <Hour>
          <Time size={16} /> Seg. a Sext. das 8 às 17h
        </Hour>
      </TopInfo>

      <TopSocial>
        <SocialGroupIcons />
      </TopSocial>
    </TopContainer>
  </Top>
)

export default HeaderTop
