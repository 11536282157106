import React from "react"
import { Link } from "gatsby"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"

import { Home } from "@styled-icons/boxicons-regular/Home"
import { ListAlt } from "@styled-icons/material-rounded/ListAlt"
import { ContactPage } from "@styled-icons/material/ContactPage"

const NavMobile = ({ onNavClick }) => (
  <List component="nav" role="navigation" aria-label="Main">
    <ListItem button component={Link} to="#top" onClick={onNavClick}>
      <ListItemIcon>
        <Home size={20} />
      </ListItemIcon>
      <ListItemText primary="Início" />
    </ListItem>

    <ListItem button component={Link} to="/#produtos" onClick={onNavClick}>
      <ListItemIcon>
        <ListAlt size={20} />
      </ListItemIcon>
      <ListItemText primary="Produtos" />
    </ListItem>

    <ListItem button component={Link} to="/#contato" onClick={onNavClick}>
      <ListItemIcon>
        <ContactPage size={20} />
      </ListItemIcon>
      <ListItemText primary="Contato" />
    </ListItem>
  </List>
)

export default NavMobile
