import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import { Container } from "../theme/global-style"

import HeaderTop from "./headerTop"
import Drawer from "./drawer"
import WhatsappContact from "./whatsappContact"
import NavDesktop from "./navDesktop"

const HeaderWrapper = styled.header`
  border-bottom: 1px solid ${props => props.theme.borderColor.black06};
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  transition: ease height 300ms;
  z-index: 100;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.borderColor.gray05};
`

const HeaderContainer = styled(Container)`
  height: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ImageWrapper = styled.div`
  height: 70px;
`

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
`

const WhatsAppWrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.screens.lg}) {
    padding-left: 20px;
    border-left: 1px solid ${({ theme }) => theme.borderColor.black08};
  }

  @media (max-width: ${({ theme }) => theme.screens.maxlg}) {
    margin-right: 30px;
    padding-right: 30px;
    border-right: 1px solid ${({ theme }) => theme.borderColor.black08};
  }

  @media (max-width: ${({ theme }) => theme.screens.maxsm}) {
    display: none;
  }
`

const DrawerWrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.screens.lg}) {
    display: none;
  }
`

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "icon.png" }) {
        childImageSharp {
          fixed(height: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <>
      <HeaderTop />

      <HeaderWrapper>
        <HeaderContainer>
          <Link to="/">
            <ImageWrapper>
              <Img fixed={data.logo.childImageSharp.fixed} alt={siteTitle} />
            </ImageWrapper>
          </Link>

          <NavWrapper>
            <NavDesktop />

            <WhatsAppWrapper>
              <WhatsappContact />
            </WhatsAppWrapper>

            <DrawerWrapper>
              <Drawer />
            </DrawerWrapper>
          </NavWrapper>
        </HeaderContainer>
      </HeaderWrapper>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
