import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Button from "@material-ui/core/Button"

const NavContainer = styled.div`
  max-width: 800px;
  margin: 35px auto 20px;
  padding: 0 10px;
  text-align: center;
  .activeNav {
    background-color: #202020 !important;
    color: #fff !important;
  }
`

const StyledButton = styled(Button)`
  color: #fff;
  background-color: #202020;
  border: 1px solid #202020;
  border-radius: 24px;
  padding: 6px 10px;
  margin: 4px;
`

const Nav = () => (
  <NavContainer id="produtos">
    <StyledButton
      size="small"
      style={{ backgroundColor: "#ffffff", color: "#202020" }}
      variant="contained"
      disableElevation
      component={Link}
      to="/"
      activeClassName="activeNav"
    >
      Armários de Ferramentas
    </StyledButton>

    <StyledButton
      size="small"
      style={{ backgroundColor: "#ffffff", color: "#202020" }}
      variant="contained"
      disableElevation
      component={Link}
      to="/armarios-e-colmeias"
      activeClassName="activeNav"
    >
      Armários e Colmeias
    </StyledButton>

    <StyledButton
      size="small"
      style={{ backgroundColor: "#ffffff", color: "#202020" }}
      variant="contained"
      disableElevation
      component={Link}
      to="/armarios-roupeiros"
      activeClassName="activeNav"
    >
      Armários Roupeiros
    </StyledButton>

    <StyledButton
      size="small"
      style={{ backgroundColor: "#ffffff", color: "#202020" }}
      variant="contained"
      disableElevation
      component={Link}
      to="/armarios-vitrines"
      activeClassName="activeNav"
    >
      Armários Vitrines
    </StyledButton>

    <StyledButton
      size="small"
      style={{ backgroundColor: "#ffffff", color: "#202020" }}
      variant="contained"
      disableElevation
      component={Link}
      to="/armarios-estantes"
      activeClassName="activeNav"
    >
      Armários Estantes
    </StyledButton>

    <StyledButton
      size="small"
      style={{ backgroundColor: "#ffffff", color: "#202020" }}
      variant="contained"
      disableElevation
      component={Link}
      to="/arquivos"
      activeClassName="activeNav"
    >
      Arquivos
    </StyledButton>

    <StyledButton
      size="small"
      style={{ backgroundColor: "#ffffff", color: "#202020" }}
      variant="contained"
      disableElevation
      component={Link}
      to="/bancadas"
      activeClassName="activeNav"
    >
      Bancadas
    </StyledButton>

    <StyledButton
      size="small"
      style={{ backgroundColor: "#ffffff", color: "#202020" }}
      variant="contained"
      disableElevation
      component={Link}
      to="/estantes-de-aco"
      activeClassName="activeNav"
    >
      Estantes de Aço
    </StyledButton>

    <StyledButton
      size="small"
      style={{ backgroundColor: "#ffffff", color: "#202020" }}
      variant="contained"
      disableElevation
      component={Link}
      to="/gaveteiros"
      activeClassName="activeNav"
    >
      Gaveteiros
    </StyledButton>

    <StyledButton
      size="small"
      style={{ backgroundColor: "#ffffff", color: "#202020" }}
      variant="contained"
      disableElevation
      component={Link}
      to="/mesas"
      activeClassName="activeNav"
    >
      Mesas e Aparadores
    </StyledButton>
  </NavContainer>
)

export default Nav
