import styled, { createGlobalStyle } from "styled-components"
import { normalize } from "styled-normalize"

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  * {
    font-family: ${props => props.theme.fontFamily.body};
  }

  html {
    scroll-behavior: smooth;
  }

  h1,h2,h3,h4,h5,h6 {
    font-family: ${props => props.theme.fontFamily.heading};
    font-weight: ${props => props.theme.fontWeight.bold};
    letter-spacing: ${({ theme }) => theme.letterSpacing.tighter};
  }

  body {
    font-size: ${props => props.theme.fontSize.sm};
    color: ${props => props.theme.colors.text};
    line-height: ${props => props.theme.lineHeight.normal};
    letter-spacing: ${props => props.theme.letterSpacing.normal};
    background-color: ${props => props.theme.colors.background};
  }

  p, ul, small {
    color: ${props => props.theme.colors.gray};
    font-weight: ${({ theme }) => theme.fontWeight.normal};
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .strong {
    font-weight: bold;
    color: #333333
  }
`

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1170px;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: ${({ theme }) => theme.screens.sm}) {
    padding-left: 30px;
    padding-right: 30px;
  }
`
