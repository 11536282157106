import React from "react"
import styled from "styled-components"
import { Whatsapp } from "@styled-icons/boxicons-logos/Whatsapp"

const ContactWhatsapp = styled.div`
  display: flex;
  justify-content: center;

  div {
    margin-left: 3px;
  }

  small {
    text-transform: uppercase;
  }

  a {
    margin: -3px 0;
    font-size: ${({ theme }) => theme.fontSize.base};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    display: block;
  }

  a:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const WhatsappIcon = styled(Whatsapp)`
  color: #25d366;
`

const WhatsappContact = () => (
  <ContactWhatsapp>
    <WhatsappIcon size={46} />
    <div>
      <small>Whatsapp</small>
      <a href="https://api.whatsapp.com/send?phone=5519988287751">
        19 98828.7751
      </a>
    </div>
  </ContactWhatsapp>
)

export default WhatsappContact
