import React from "react"
import styled from "styled-components"

import { Container } from "../../theme/global-style"

import bg from "../../images/hero-bg.jpg"

const HeroSection = styled.section`
  background-image: url(${bg});
  background-position: center right;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 360px;

  display: flex;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.screens.md}) {
    align-items: flex-end;
  }
`

const ContainerExt = styled(Container)`
  flex-grow: 1;
`

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h1,
  h2,
  p {
    color: ${({ theme }) => theme.colors.white};
    background-color: rgba(150, 150, 187, 0.1);
    padding: 0 4px 0;
  }

  h2 {
    font-size: ${({ theme }) => theme.fontSize.lg};
    margin-bottom: 5px;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 10px;
    line-height: 41px;
  }

  p {
    font-size: ${({ theme }) => theme.fontSize.sm};
    margin: 0;
  }

  @media (min-width: ${({ theme }) => theme.screens.md}) {
    padding-bottom: 30px;
  }
`

const Section = styled.section`
  background-color: ${({ theme }) => theme.colors.muted};
  display: flex;
  align-items: center;
  padding: 20px 0;
  text-align: center;
`

const Wrapper = styled(Container)`
  max-width: 750px;

  h1 {
    color: ${({ theme }) => theme.colors.primary};
    /* font-size: ${({ theme }) => theme.fontSize.lg}; */
  }

  p {
    margin-top: -15px;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  @media (min-width: ${({ theme }) => theme.screens.md}) {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${({ theme }) => theme.fontSize[8]};
  }
`

const Hero = () => (
  <>
    <HeroSection>
      <ContainerExt>
        <HeroContent>
          <h2>DEC Móveis Planejados</h2>
          <h1>Empresa de móveis Direto da Fábrica em Iracemápolis SP</h1>
          <p>Atacado/Varejo</p>
        </HeroContent>
      </ContainerExt>
    </HeroSection>

    <Section>
      <Wrapper>
        <div>
          <h1>Confira nosso Catálogo</h1>
          <p>
            Nossos armários possuem uma ótima qualidade, feito diretamente da
            fábrica por profissionais qualificados respeitando todas normas das
            diretrizes, para assim sair em perfeitas condições.
          </p>
        </div>
      </Wrapper>
    </Section>
  </>
)

export default Hero
