import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Button from "@material-ui/core/Button"

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-self: stretch;

  margin-right: 10px;

  .MuiButtonBase-root {
    padding: 10px 20px;
  }

  .MuiButton-root:hover,
  .MuiButton-root:active,
  .MuiButton-root:focus {
    color: ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.transparent};
    border-radius: ${({ theme }) => theme.borderRadius.none};
  }

  @media (max-width: ${({ theme }) => theme.screens.maxlg}) {
    display: none;
  }
`

const NavDesktop = () => (
  <Nav>
    <Button component={Link} to="/" disableElevation>
      Início
    </Button>

    <Button component={Link} to="/#produtos" disableElevation>
      Catálogo de Produtos
    </Button>

    <Button component={Link} to="/#contato" disableElevation>
      Contato
    </Button>
  </Nav>
)

export default NavDesktop
