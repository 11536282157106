export const theme = {
  screens: {
    xsm: "425px",
    sm: "640px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    maxsm: "639.98px",
    maxmd: "767.98px",
    maxlg: "991.98px",
    maxxl: "1199.98px",
  },
  colors: {
    transparent: "transparent",
    black: "#000",
    white: "#fff",
    text: "#1a202c",
    background: "#fff",
    primary: "#5f6d7d",
    secondary: "#30c",
    red: "#e03131",
    muted: "#f6f6f6",
    lightgray: "#D3D3D3",
    silver: "#C0C0C0",
    darkgray: "#A9A9A9",
    gray: "#575757",
    dimgray: "#696969",
    lightslategray: "#778899",
    slategray: "#708090",
    darkslategray: "#2F4F4F",
    green: "#005C2C",
  },
  spacing: {
    px: "1px",
    0: "0",
    1: "0.25rem",
    2: "0.5rem",
    3: "0.75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    8: "2rem",
    10: "2.5rem",
    12: "3rem",
    16: "4rem",
    20: "5rem",
    24: "6rem",
    32: "8rem",
    40: "10rem",
    48: "12rem",
    56: "14rem",
    64: "16rem",
  },
  fontFamily: {
    body: "Poppins",
    heading: "Poppins",
    monospace: "Menlo, monospace",
  },
  fontSize: {
    xs: "0.75rem",
    sm: "0.875rem",
    base: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    xl2: "1.5rem",
    xl3: "1.875rem",
    xl4: "2.25rem",
    xl5: "3rem",
    xl6: "4rem",
  },
  fontWeight: {
    hairline: "100",
    thin: "200",
    light: "300",
    normal: "400",
    medium: "500",
    semibold: "600",
    bold: "700",
    extrabold: "800",
    black: "900",
  },
  letterSpacing: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
  },
  lineHeight: {
    none: "1",
    tight: "1.25",
    snug: "1.375",
    normal: "1.5",
    relaxed: "1.625",
    loose: "2",
  },
  borderColor: {
    black06: "rgba(0, 0, 0, 0.06)",
    black08: "rgba(0, 0, 0, 0.08)",
    gray05: "rgba(234, 234, 234, .5)",
  },
  borderRadius: {
    none: "0",
    sm: "0.125rem",
    default: "0.25rem",
    lg: "0.5rem",
    xlg: "1rem",
    xxlg: "1rem",
    xxxlg: "1.5rem",
    full: "9999px",
  },
  borderWidth: {
    default: "1px",
    0: "0",
    2: "2px",
    4: "4px",
    8: "8px",
  },
  boxShadow: {
    default: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    lg:
      "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
    xl:
      "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
    inner: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
    outline: "0 0 0 3px rgba(66, 153, 225, 0.5)",
    none: "none",
  },
  zIndex: {
    auto: "auto",
    0: "0",
    10: "10",
    20: "20",
    30: "30",
    40: "40",
    50: "50",
  },
}
