import React from "react"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import Button from "@material-ui/core/Button"
import { Menu } from "@styled-icons/boxicons-regular/Menu"
import styled from "styled-components"

import WhatsappContact from "./whatsappContact"
import SocialGroupIcons from "./socialGroupIcons"
import NavMobile from "./navMobile"

const NavDrawerButton = styled(Button)`
  background-color: ${props => props.theme.colors.primary};
  padding: 10px 12px;
  min-width: 30px;
  color: ${props => props.theme.colors.white};

  .MuiButton {
    &::hover {
      background-color: ${props => props.theme.colors.primary};
    }
  }
`

const DrawerWrapper = styled.div`
  width: 250px;
  height: 100%;
  background-color: #f3f3f3;

  display: flex;
  flex-direction: column;
`

const Nav = styled.div`
  flex-grow: 1;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`

const SocialWrapper = styled.div`
  padding: 25px 0;
  border-bottom: 1px solid ${props => props.theme.borderColor.black06};
`

const WPContactWrapper = styled.div`
  padding: 20px 0;
  border-top: 1px solid ${props => props.theme.borderColor.black06};
`

const Drawer = () => {
  const [open, setState] = React.useState(false)

  const toggleDrawer = () => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setState(!open)
  }

  return (
    <>
      <NavDrawerButton
        variant="contained"
        disableElevation
        onClick={toggleDrawer(true)}
        aria-label="nav"
      >
        <Menu size={20} />
      </NavDrawerButton>

      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <DrawerWrapper role="presentation" onKeyDown={toggleDrawer(false)}>
          <SocialWrapper>
            <SocialGroupIcons />
          </SocialWrapper>

          <Nav>
            <NavMobile onNavClick={toggleDrawer(false)} />
          </Nav>

          <WPContactWrapper>
            <WhatsappContact />
          </WPContactWrapper>
        </DrawerWrapper>
      </SwipeableDrawer>
    </>
  )
}

export default Drawer
