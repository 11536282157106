import React from "react"
import styled from "styled-components"
import { Map } from "@styled-icons/boxicons-regular/Map"
import { Facebook } from "@styled-icons/boxicons-logos/Facebook"
import { Instagram } from "@styled-icons/boxicons-logos/Instagram"

export const Social = styled.div`
  display: flex;
  justify-content: center;

  a {
    padding: 0 7px;
  }
`

const IconFacebook = styled(Facebook)`
  background: #3b5998;
  color: ${props => props.theme.colors.white};
  padding: 5px;
  border-radius: 50%;
`

const IconInstagram = styled(Instagram)`
  background: #d6249f;
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  color: ${props => props.theme.colors.white};
  padding: 5px;
  border-radius: 50%;
`

const IconMap = styled(Map)`
  background: #dd4b39;
  color: ${props => props.theme.colors.white};
  padding: 5px;
  border-radius: 50%;
`

const SocialGroupIcons = () => (
  <Social>
    <a
      href="https://www.facebook.com/nevaco.tudoemaco"
      aria-label="Visitar página no Facebook"
    >
      <IconFacebook size={23} />
    </a>

    <a
      href="https://www.instagram.com/nevacotudoemaco/"
      aria-label="Visitar página no Instagram"
    >
      <IconInstagram size={23} />
    </a>

    <a
      href="https://goo.gl/maps/LRjDmi6f6PidxN7R8"
      aria-label="Visitar local no Google Maps"
    >
      <IconMap size={23} />
    </a>
  </Social>
)

export default SocialGroupIcons
